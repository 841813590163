.resetPassword {
  min-height: 100vh;
  background-image: linear-gradient(to right, #fff5bc, #cec1ff);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2vmax;
  box-sizing: border-box;
}

.resetPasswordForm {
  background-color: white;
  width: 50%;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 2vmax;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resetPasswordInputs {
  box-sizing: border-box;
  padding: 1vmax 2vmax;
  width: 80%;
  border-radius: 40px;
  border: 1px solid #ccc;
  margin: 2vmax;
  font: 100 1.2rem "Roboto", sans-serif;
  outline: none;
}
.resetPasswordForm > a {
  align-self: flex-end;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.342);
  margin: 2vmax;
}

@media screen and (max-width: 600px) {
  .resetPasswordForm {
    width: 100%;
  }

  .resetPasswordInputs {
    padding: 4vw 8vw;
    margin: 2vmax;
    font: 100 1.2rem "Roboto", sans-serif;
    margin: 4vw;
  }

  .resetPasswordForm > a {
    align-self: center;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.342);
  }
}
